<template>
  <div>
    <v-app>
    <router-view></router-view>
    <SnackbarNew/>
    </v-app>
    <Footer />
  </div>
</template>
<script>
import SnackbarNew from "@/components/includes/SnackbarNew";
export default {
  name: "simple-layout",
  data() {
    return {
    };
  },
  components: {
    Footer: () => import("@/components/includes/Footer"),
    SnackbarNew,
  },
  computed: {},
  mounted() {
  },
};
</script>
